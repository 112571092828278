var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("NEWSUI", {
    ref: "UI",
    attrs: {
      security: _vm.security,
      dataModel: _vm.newsList,
      newsDetail: _vm.newsDetail,
      skin: _vm.skin,
      showLoading: _vm.showLoading,
      listLoading: _vm.listLoading,
      errorCode: _vm.error.code,
      availableProviders: _vm.availableProviders,
      settings: _vm.settings,
      labels: _vm.mergeLabels(_vm.defaultConfig.labels),
      formatter: _vm.formatter,
      "mwc-id": _vm.mwcId
    },
    on: {
      track: _vm.trackEvent,
      "news-type-or-source-changed": _vm.changeNewsTypeOrSource,
      "settings-changed": _vm.changeSettings,
      "details-displayed": _vm.getNewsDetail,
      "aside-scrolled": _vm.loadingData
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }