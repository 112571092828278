<template>
    <article :class="classes" ref="newsArticle" v-show="visible" tabindex="0">
        <markets-ui-button
            variation="icon-only"
            icon="caret-circle-left"
            size="medium"
            :skin="skin"
            :text="labels.back"
            ref="back"
            :class="namespace('button-back--top')"
            @click.stop="shrink"
        />
        <section :class="namespace('detail__content')">
            <div v-if="!!newsDetail.content">
                <h1 :class="namespace('detail__header')">
                    {{ newsDetail.title }}
                </h1>
                <p :class="namespace('detail__author')">
                    <template v-if="newsDetail.authors">
                        {{ labels.by }}{{ newsDetail.authors }} | </template
                    >{{ newsDetail.time }}
                </p>
                <hr />
                <p v-html="newsDetail.content"></p>
            </div>
            <markets-ui-alert
                v-if="newsDetail.hasData === false"
                :message="labels.noContent"
                :skin="skin"
            ></markets-ui-alert>
        </section>
        <markets-ui-button
            v-if="!!newsDetail.content"
            variation="icon-only"
            icon="caret-circle-left"
            size="medium"
            :skin="skin"
            :text="labels.back"
            @click.stop="shrink"
        />
    </article>
</template>
<script>
import BaseMixin from './mixins';
export default {
    mixins: [BaseMixin],
    name: 'newsArticle',
    props: {
        newsDetail: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            required: false
        },
        labels: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            isSpread: false
        };
    },
    computed: {
        classes() {
            const cls = [this.namespace('detail')];
            if (this.isMobile) {
                cls.push(this.namespace('detail--spread'));
            }
            if (!this.visible && this.isMobile) {
                cls.push(this.namespace('mobile-detail-hide'));
            }
            return cls;
        }
    },
    watch: {
        newsDetail: {
            handler() {
                this.isSpread = this.isMobile ? true : false;
                this.$nextTick(() => {
                    this.$el.focus();
                });
            },
            deep: true
        },
        isSpread(value) {
            if (value) {
                this.spread();
            }
        }
    },
    methods: {
        shrink() {
            if (this.isMobile) {
                this.$refs.newsArticle.classList.remove('spread-animation');
                this.$refs.newsArticle.classList.add('shrink-animation');
                this.isSpread = false;
            }
            this.$emit('hide');
        },
        spread() {
            this.$refs.newsArticle.classList.remove('shrink-animation');
            this.$refs.newsArticle.classList.add('spread-animation');
        },
        focusOnContent() {
            this.$nextTick(() => {
                this.$el.focus();
            });
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
@import '@mds/utils-scss';
$namespace: 'mwc-markets-news';
.#{$namespace} {
    .#{$namespace}-detail {
        width: calc(100% - 350px);
        overflow: auto;
        padding: $mds-space-half-x;
        position: relative;
        &:focus {
            outline: none;
        }
        h1,
        p {
            font-size: $mds-typography-font-size-m;
            line-height: 20px;
        }
        &-header {
            font-weight: 700;
        }
        &__author {
            font-weight: $mds-typography-font-weight-light;
            padding-bottom: 5px;
        }
        &__content {
            padding: 0px $mds-space-2-x $mds-space-2-x $mds-space-2-x;
            overflow-y: auto;
        }
        .markets-ui-button {
            @include mds-accessibly-hidden();
        }
        &--spread {
            width: 100%;
            z-index: 1;
            background: $mds-color-white;
            padding-left: 20px;
            overflow-x: hidden;
            .#{$namespace}-detail__content {
                height: calc(100% - 55px);
            }
            .#{$namespace}-button-back--top {
                position: static;
                z-index: inherit;
            }
        }
        &.#{$namespace}-mobile-detail-hide {
            transform: translateX(100%);
        }
        p {
            strong,
            h1,
            b {
                line-height: 35px;
            }
            a {
                color: $mds-color-neutral-37;
                text-decoration: none;
                &:hover {
                    color: $mds-interactive-color-primary-default;
                }
            }
        }
        pre {
            white-space: pre-wrap;
        }
        &.spread-animation {
            -webkit-animation: spread 300ms linear 0ms 1 normal;
            animation: spread 300ms linear 0s 1 normal;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        &.shrink-animation {
            -webkit-animation: shrink 300ms linear 0s 1 normal;
            animation: shrink 300ms linear 0s 1 normal;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }

    // spread animation
    @-webkit-keyframes spread {
        from {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
        to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    @keyframes spread {
        from {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
        to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    @-webkit-keyframes shrink {
        from {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
        to {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }

    @keyframes shrink {
        from {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
        to {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }
}
</style>
