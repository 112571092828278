export default {
    props: {
        skin: {
            type: String,
            required: false
        },
        isMobile: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        namespace: (context = '') =>
            `mwc-markets-news${context ? '-' : ''}${context}`
    }
};
