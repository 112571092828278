var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      ref: "newsArticle",
      class: _vm.classes,
      attrs: { tabindex: "0" }
    },
    [
      _c("markets-ui-button", {
        ref: "back",
        class: _vm.namespace("button-back--top"),
        attrs: {
          variation: "icon-only",
          icon: "caret-circle-left",
          size: "medium",
          skin: _vm.skin,
          text: _vm.labels.back
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.shrink($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "section",
        { class: _vm.namespace("detail__content") },
        [
          !!_vm.newsDetail.content
            ? _c("div", [
                _c("h1", { class: _vm.namespace("detail__header") }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.newsDetail.title) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { class: _vm.namespace("detail__author") },
                  [
                    _vm.newsDetail.authors
                      ? [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.labels.by) +
                              _vm._s(_vm.newsDetail.authors) +
                              " | "
                          )
                        ]
                      : _vm._e(),
                    _vm._v(_vm._s(_vm.newsDetail.time) + "\n            ")
                  ],
                  2
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.newsDetail.content) }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.newsDetail.hasData === false
            ? _c("markets-ui-alert", {
                attrs: { message: _vm.labels.noContent, skin: _vm.skin }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !!_vm.newsDetail.content
        ? _c("markets-ui-button", {
            attrs: {
              variation: "icon-only",
              icon: "caret-circle-left",
              size: "medium",
              skin: _vm.skin,
              text: _vm.labels.back
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.shrink($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }