<template>
    <NEWSUI
        ref="UI"
        :security="security"
        :dataModel="newsList"
        :newsDetail="newsDetail"
        :skin="skin"
        :showLoading="showLoading"
        :listLoading="listLoading"
        :errorCode="error.code"
        :availableProviders="availableProviders"
        :settings="settings"
        :labels="mergeLabels(defaultConfig.labels)"
        :formatter="formatter"
        :mwc-id="mwcId"
        @track="trackEvent"
        @news-type-or-source-changed="changeNewsTypeOrSource"
        @settings-changed="changeSettings"
        @details-displayed="getNewsDetail"
        @aside-scrolled="loadingData"
    >
    </NEWSUI>
</template>
<script>
import labels from './assets/labels.json';
import mwcMarketsCore from 'mwc-markets-core';
import NEWSUI from './news-ui';
import { NEWS_TYPES, getNewsTypes } from './common';
const { mixins } = mwcMarketsCore;

export default {
    name: 'mwc-markets-news',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    props: ['symbol'],
    data() {
        return {
            name: 'mwc-markets-news',
            defaultConfig: {
                settings: {
                    symbol: 'XNYS:IBM',
                    autoHeight: false,
                    showHeader: false,
                    showSetting: true,
                    showBorder: false,
                    languageId: 'en-US',
                    sourceCodes: '',
                    showProviderSetting: true,
                    newsType: NEWS_TYPES.INVESTMENT,
                    newsTypeOptions: getNewsTypes().join(','),
                    count: 20,
                    mobileBreakPoint: 700,
                    skin: '',
                    topStoriesSourceCode: 'BZBZMKT',
                    dailyBriefingsSourceCode: 'DJNMPNAE',
                    usMarketsSourceCodes: '',
                    usMarketsDJNewsTypes: '',
                    investmentDJNewsTypes: ''
                },
                labels: labels,
                format: {
                    date: {
                        dataType: 'date',
                        day: 'numeric',
                        month: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    }
                },
                intlNamespace: 'mwc-markets-news'
            },
            security: {},
            newsList: null,
            length: 0,
            maxCount: 0,
            newsDetail: null,
            changeType: false,
            newsType: NEWS_TYPES.INVESTMENT,
            showLoading: true,
            listLoading: false,
            availableProviders: null
        };
    },
    created() {
        this.initData({
            symbol: this.settings.symbol,
            count: this.settings.count,
            newsType: this.settings.newsType
        });
        // Tracking: change symbol.
        this.trackEvent({
            name: 'symbol-change',
            value: this.settings.symbol
        });
    },
    watch: {
        symbol(value) {
            if (value && typeof value === 'string') {
                this.changeSymbol(value);
                this.trackEvent({
                    name: 'symbol-change',
                    value
                });
            } else {
                this._log('ERROR', 'symbol attribute should be string.');
            }
        }
    },
    methods: {
        initData(params) {
            if (!this.mktdata) {
                return;
            }
            this.maxCount = +params.count;
            this.newsType = params.newsType;
            this.setStartEnd();
            const requests = [
                this.mktdata.newsSources().then(data => {
                    this.entitlementData = data;
                    return data;
                })
            ];
            if (this.newsType === NEWS_TYPES.INVESTMENT) {
                requests.push(this.generatedSecurityInfo(params.symbol));
            }
            Promise.all(requests)
                .then(data => {
                    this.generateAvailableProviders(data[0]);
                })
                .catch(e => {
                    this.showLoading = false;
                });
        },
        getNewsList(showLoading = true) {
            this.showLoading = showLoading;
            if (this.newsType === 'mostViewed') {
                this.getNewsMostViewed();
                return;
            }
            let params = {
                withLogo: true
            };
            if (this.start >= 0 && this.end > 0) {
                params.startAt = this.start;
                params.endAt = this.end;
            } else {
                params.maxCount = this.count;
            }
            if (this.complexQuery) {
                params.complexQuery = this.complexQuery;
            }
            if (this.newsType === NEWS_TYPES.INVESTMENT && this.security) {
                params.idType = 'SC';
                params.symbols = this.security.securityId;
                if (this.sourceCode) {
                    params.sourceCode = this.sourceCode.join(',');
                }
            } else if (
                this.newsType === NEWS_TYPES.US_MARKETS &&
                this.sourceCode
            ) {
                params.sourceCode = this.sourceCode.join(',');
            } else if (this.newsType === NEWS_TYPES.TOP_STORIES) {
                params.sourceCode = this.settings.topStoriesSourceCode;
                params.srchType = 't';
                params.maxCount = 10;
                params.keywords = 'Market Update';
                delete params.startAt;
                delete params.endAt;
            } else if (this.newsType === NEWS_TYPES.DAILY_BRIEFINGS) {
                params.complexQuery = `(sourceCode:${this.settings.dailyBriefingsSourceCode} AND keywords:("N/NAR" "N/10NL" "N/WANL"))`;
            }
            this.mktdata
                .newsHeadlines(params)
                .then(data => {
                    this.generateNewsList(data);
                    this.listLoading = false;
                })
                .catch(e => {
                    this.showLoading = false;
                });
        },
        getNewsDetail(resourceId) {
            this.newsDetail = null;
            this.showLoading = true;
            this.error = {};
            this.mktdata
                .newsView({
                    resourceId: resourceId
                })
                .then(data => {
                    this.newsDetail = data;
                    this.showLoading = false;
                    this.renderCompleted = true;
                })
                .catch(e => {
                    this.showLoading = false;
                });
        },
        getNewsMostViewed() {
            this.mktdata
                .newsMostViewed({
                    maxCount: this.maxCount,
                    recentDays: 1,
                    withLogo: true
                })
                .then(data => {
                    this.generateNewsList(data);
                })
                .catch(e => {
                    this.showLoading = false;
                });
        },
        generateAvailableProviders(data) {
            if (data && data.length) {
                const providersSourceMap = new Map();
                data.forEach(element => {
                    const { sourceCode, vendor } = element;
                    if (providersSourceMap.has(vendor)) {
                        providersSourceMap
                            .get(vendor)
                            .sourceCodes.push(sourceCode);
                    } else {
                        providersSourceMap.set(vendor, {
                            vendor,
                            sourceCodes: [sourceCode]
                        });
                    }
                });
                this.availableProviders = Array.from(
                    providersSourceMap.values()
                );
            }
            this.showLoading = false;
        },
        setStartEnd() {
            this.start = 0;
            this.count = +this.maxCount;
            this.end = this.count - 1;
            this.newsList = null;
            this.newsDetail = null;
        },
        changeNewsTypeOrSource({ sourceCodes = [], newsType, complexQuery }) {
            const originalType = this.newsType;
            this.sourceCode = sourceCodes;
            this.newsType = newsType;
            this.complexQuery = complexQuery;
            this.error = {};
            if (
                this.newsType === NEWS_TYPES.INVESTMENT &&
                originalType !== newsType
            ) {
                this.changeSymbol(this.security.queryKey);
            } else {
                this.setStartEnd();
                this.getNewsList();
            }
        },
        changeSymbol(symbol) {
            if (this.newsType === NEWS_TYPES.INVESTMENT) {
                this.setStartEnd();
                this.generatedSecurityInfo(symbol).then(() => {
                    if (!this.availableProviders && this.entitlementData) {
                        this.generateAvailableProviders(this.entitlementData);
                    } else {
                        this.getNewsList();
                    }
                });
            }
        },
        generatedSecurityInfo(symbol) {
            if (symbol && this.mktdata) {
                this.acceptTicker(symbol);
                return this.mktdata
                    .securities([symbol])
                    .then(securities => {
                        const security = securities[0];
                        if (security.securityId) {
                            this.security = security;
                            return security.securityId;
                        } else {
                            this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                            return Promise.reject();
                        }
                    })
                    .catch(() => {
                        this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                        return Promise.reject();
                    });
            } else {
                this.security = {};
                return Promise.resolve(this.security);
            }
        },
        generateNewsList(data) {
            if (!this.newsList) {
                this.newsList = data;
                // get first news detail
                if (this.newsList.news && this.newsList.news.length) {
                    this.error = {};
                } else {
                    if (this.newsType === 'topStories') {
                        this.error = {
                            code: 'noTopStories'
                        };
                    } else {
                        this.error = {
                            code: 'noNews'
                        };
                    }
                }
            } else {
                this.error = {};
                this.newsList.news = [...this.newsList.news, ...data.news];
            }
            this.showLoading = false;
            this.changeType = false;
            this.renderCompleted = true;
        },
        loadingData() {
            this.count = this.maxCount;
            this.start += this.count;
            this.end += this.count;
            this.listLoading = true;
            this.getNewsList(false);
        },
        _setError(error) {
            this.error = error;
            this.security = {};
            this.showLoading = false;
        }
    },
    components: {
        NEWSUI
    }
};
</script>
