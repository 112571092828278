export const NEWS_TYPES = {
    INVESTMENT: 'investment',
    US_MARKETS: 'usMarkets',
    MOST_VIEWED: 'mostViewed',
    TOP_STORIES: 'topStories',
    DAILY_BRIEFINGS: 'dailyBriefings'
};

export const US_MARKETS_KEYWORDS = 'R/US';

export const US_MARKETS_SOURCE_CODES = [
    'AWRAWR',
    'BRIEFINGSTOCK_MARKET_UPDATE',
    'BWIPREMBWIPREM',
    'GLBNEWSDOTCOM',
    'PRNPREMPRNPREM',
    'DJNMPNAE',
    'DJNMNDJG',
    'ZACKSZIRGENERIC',
    'BZBZPR',
    'BZBZMKT'
];

export const DJ_NEWS_TYPES = [
    {
        id: 'marketNews',
        keywords: '("N/MKT")'
    },
    {
        id: 'companyNews',
        keywords: '("N/CNW")'
    },
    {
        id: 'topNews',
        keywords: '("N/TNW")'
    },
    {
        id: 'headLine',
        keywords: '("P/HDL")'
    },
    {
        id: 'pressRelease',
        keywords: '("N/PRL")'
    },
    {
        id: 'earnings',
        keywords: '("N/ERN")'
    },
    {
        id: 'corporateActions',
        keywords: '("N/CAC")'
    },
    {
        id: 'analystRatings',
        keywords: '("N/ARG")'
    },
    {
        id: 'mergersAndAcquisitions',
        keywords: '("N/TNM")'
    },
    {
        id: 'DJExclusiveNews',
        keywords: '("P/PMDM")'
    },
    {
        id: 'DJExclusiveAnalysis',
        keywords: '("P/PMDA")'
    },
    {
        id: 'significantNews',
        keywords: '("P/SIC")'
    },
    {
        id: 'hotStories',
        keywords: '("N/HOT")'
    },
    {
        id: 'forex',
        keywords: '("N/FRX" AND "P/RCC")'
    },
    {
        id: 'commodities',
        keywords: '("N/CMD" AND "P/RCC")'
    },
    {
        id: 'energy',
        keywords: '("N/PET" AND "P/RCC"),("N/ENY" AND "P/RCC")'
    },
    {
        id: 'fixedIncome',
        keywords: '("N/BON" AND "P/RCC")'
    },
    {
        id: 'other'
    }
];

export function getNewsTypes() {
    const types = [];
    for (const key in NEWS_TYPES) {
        types.push(NEWS_TYPES[key]);
    }
    return types;
}

export function isDJNews(provider = {}) {
    return provider.id === 'DowJones';
}
