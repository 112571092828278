var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { ref: "newsAside" },
    [
      _c("div", { ref: "scrollEl", class: _vm.namespace("ul-container") }, [
        _c(
          "ul",
          {
            ref: "newsContent",
            class: _vm.namespace("ul"),
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.getNewsDetail($event)
              },
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getNewsDetail($event)
              }
            }
          },
          _vm._l(_vm.dataObject.news, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                class:
                  item.resourceId === _vm.activeId
                    ? [_vm.namespace("li"), _vm.namespace("li-active")]
                    : _vm.namespace("li"),
                attrs: {
                  tabindex: "0",
                  resourceId: item.resourceId,
                  "aria-describedby": _vm.ariaDescribedbyId
                },
                on: {
                  mouseenter: function($event) {
                    return _vm.showTip(item, $event)
                  },
                  mouseleave: _vm.hideTip,
                  focus: function($event) {
                    return _vm.showTip(item, $event)
                  },
                  blur: _vm.hideTip
                }
              },
              [
                _c("img", {
                  class: _vm.namespace("logo"),
                  attrs: {
                    alt: item.logoAlt,
                    src: "data:image/png;base64," + item.logoBase64Image
                  }
                }),
                _vm._v(" "),
                _c("p", { class: _vm.namespace("aside_title") }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.title) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("p", { class: _vm.namespace("aside_time") }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.publicationTime) +
                      "\n                "
                  )
                ])
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "markets-ui-tooltip",
        {
          ref: "asidePopover",
          class: _vm.namespace("aside__popover"),
          attrs: {
            visible: _vm.tooltipVisible,
            "triggered-by": _vm.triggeredId,
            skin: _vm.skin
          }
        },
        [
          _c("p", { class: _vm.namespace("aside__popover-content") }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ]
      ),
      _vm._v(" "),
      _c("markets-ui-loading", {
        attrs: { skin: _vm.skin, visible: _vm.asideLoading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }