<template>
    <markets-ui-container
        :class="classes"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
        ref="newContent"
    >
        <div slot="content" :class="namespace('body')">
            <div
                :class="namespace('navigation')"
                v-if="showWebLayout && !errorMessage"
            >
                <markets-ui-menus
                    v-if="hasMenu"
                    :dataModel="menuNewsTypes"
                    @change="changeNewsType"
                    :skin="watchedSettings.skin"
                ></markets-ui-menus>
                <markets-ui-button
                    v-if="
                        watchedSettings.newsType === 'investment' ||
                            watchedSettings.newsType === 'usMarkets'
                    "
                    icon-right="caret-down"
                    :text="customButtonName"
                    :skin="watchedSettings.skin"
                    :aria-expanded="dataPointsExpanded"
                    @click="handleCustomDataPointsClick"
                ></markets-ui-button>
            </div>
            <section
                :class="[namespace('main'), namespace('sub-component')]"
                v-if="!errorMessage"
            >
                <newsAside
                    :class="asideClass"
                    :dataObject="dataObject"
                    :asideLoading="listLoading"
                    :isMobile="isMobile"
                    :skin="watchedSettings.skin"
                    :activeResourceId="dataDetail.resourceId"
                    :newsType="watchedSettings.newsType"
                    @item-clicked="getNewsDetail"
                    @scroll="asideScroll"
                    ref="aside"
                ></newsAside>
                <newsContent
                    v-if="!errorMessage"
                    :newsDetail="dataDetail"
                    :skin="watchedSettings.skin"
                    :visible="newsContentVisible"
                    :isMobile="isMobile"
                    :labels="initedLabels"
                    @hide="hideDetail"
                    ref="content"
                ></newsContent>
            </section>
        </div>
        <markets-ui-settings
            ref="newsUiSettings"
            slot="settings"
            :class="namespace('settings')"
            :done-disable="!!settingsErrorMsg"
            :show-done="settingsPopoverShowDone"
            :title="settingsPopoverTitle"
            :skin="watchedSettings.skin"
            :showBack="!!settingsPopoverNavSelected && !showWebLayout"
            :showSearchField="showProviderSetting"
            :triggered-by="settingsPopoverTriggeredId"
            :visible="settingsPopoverVisible"
            :error-message="settingsErrorMsg"
            @hide="hideSettingsPopover"
            @back="changeSettingsNav"
            @done="changeSourceCode"
            @search-key-changed="filterProviders"
        >
            <markets-ui-settings-navigation
                v-if="!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNav"
                @change="changeSettingsNav"
                slot="content"
            >
            </markets-ui-settings-navigation>
            <markets-ui-list
                size="small"
                v-if="!!showNewsTypeSetting"
                :skin="watchedSettings.skin"
                :data-model="newsTypes"
                variation="selection"
                @change="changeNewsType"
                slot="content"
            >
            </markets-ui-list>
            <markets-ui-form slot="content" v-if="showProviderSetting">
                <markets-ui-checkbox-group
                    :skin="watchedSettings.skin"
                    :data-model="providersList"
                    @change="changeCheckedProviders"
                />
            </markets-ui-form>
        </markets-ui-settings>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import newsAside from './components/newsAside';
import newsContent from './components/newsContent';
import {
    NEWS_TYPES,
    US_MARKETS_KEYWORDS,
    US_MARKETS_SOURCE_CODES,
    DJ_NEWS_TYPES,
    isDJNews
} from './common';
const { utils, mixins } = mwcMarketsCore;
export default {
    mixins: [mixins.componentUI],
    name: 'mwc-markets-news-ui',
    props: {
        security: {
            type: Object,
            default() {
                return {};
            }
        },
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        },
        listLoading: {
            type: Boolean,
            default: false
        },
        availableProviders: {
            type: Array
        },
        newsDetail: {
            type: Object
        }
    },
    data() {
        return {
            watchedSettings: {
                width: null,
                hasActiveNews: false
            },
            investmentNewsProviders: [],
            usMarketsProviders: [],
            settingsPopoverNavSelected: null,
            settingsPopoverVisible: false,
            settingsPopoverShowDone: false,
            settingsPopoverTriggeredId: null,
            settingsPopoverTitle: null,
            searchKey: '',
            backBtnClicked: false,
            settingsErrorMsg: ''
        };
    },
    computed: {
        hasMenu() {
            return (
                this.watchedSettings.newsType !== NEWS_TYPES.INVESTMENT &&
                this.watchedSettings.newsType !== NEWS_TYPES.TOP_STORIES &&
                this.watchedSettings.newsType !== NEWS_TYPES.DAILY_BRIEFINGS
            );
        },
        classes() {
            const array = [this.namespace()];
            if (this.showWebLayout) {
                array.push(this.namespace('web-layout'));
            }
            return array;
        },
        asideClass() {
            const cls = [this.namespace('aside')];
            if (this.isMobile) {
                cls.push(this.namespace('aside--mobile'));
                if (this.newsContentVisible) {
                    cls.push(this.namespace('aside--hide'));
                }
            }
            return cls;
        },
        newsContentVisible() {
            if (!this.isMobile) {
                return true;
            } else {
                if (this.backBtnClicked) {
                    return false;
                } else {
                    return this.watchedSettings.hasActiveNews;
                }
            }
        },
        isMobile() {
            return this.watchedSettings.width < this.settings.mobileBreakPoint;
        },
        customButtonName() {
            return this.initedLabels['newsProviders'];
        },
        settingsPopoverNav() {
            const Nav = [];
            if (this.settings.showProviderSetting) {
                Nav.push({
                    id: 'providers',
                    name: `${
                        this._getProviders().filter(provider => {
                            return provider.selected;
                        }).length
                    } ${this.initedLabels['selected']}`,
                    title: this.initedLabels['newsProviders'],
                    disabled:
                        this.watchedSettings.newsType ===
                            NEWS_TYPES.MOST_VIEWED ||
                        this.watchedSettings.newsType ===
                            NEWS_TYPES.TOP_STORIES ||
                        this.watchedSettings.newsType ===
                            NEWS_TYPES.DAILY_BRIEFINGS
                });
            }
            if (this.newsTypes) {
                Nav.push({
                    id: 'types',
                    name: `${this.initedLabels[this.watchedSettings.newsType]}`,
                    title: this.initedLabels['newsTypes'],
                    disable: false
                });
            }
            return Nav;
        },
        newsTypes() {
            if (this.watchedSettings.newsTypeOptions) {
                return this.watchedSettings.newsTypeOptions
                    .split(',')
                    .map(newsType => {
                        return {
                            id: newsType,
                            name: this.initedLabels[newsType],
                            selected: newsType === this.watchedSettings.newsType
                        };
                    });
            } else {
                return null;
            }
        },
        menuNewsTypes() {
            return ['usMarkets', 'mostViewed'].map(newsType => {
                return {
                    id: newsType,
                    name: this.initedLabels[newsType],
                    selected: newsType === this.watchedSettings.newsType
                };
            });
        },
        showProviderSetting() {
            return (
                !!this.settingsPopoverNavSelected && !this.showNewsTypeSetting
            );
        },
        showNewsTypeSetting() {
            return (
                this.newsTypes &&
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'types'
            );
        },
        dataObject() {
            const newsData = {
                news: [],
                total: 0
            };
            if (this.dataModel && this.dataModel.news) {
                this.dataModel.news.forEach(item => {
                    newsData.news.push(this.transformNewsData(item));
                });
                newsData.total = this.dataModel.total;
            }
            return newsData;
        },
        dataDetail() {
            let detail = {};
            if (this.newsDetail && this.newsDetail.content) {
                detail = {
                    authors: this.newsDetail.authors.join(','),
                    content: this.changeATagRedirect(this.newsDetail.content),
                    title: this.newsDetail.title,
                    time: this.formatter.date(
                        new Date(this.newsDetail.publicationTime)
                    ),
                    resourceId: this.newsDetail.resourceId
                };
            } else if (this.newsDetail) {
                detail.hasData = false;
            }
            return detail;
        },
        newsTypeAndSource() {
            const providers = this._getProviders() || [];
            const { keywords } = this._getDJNewsTypesByProviders(providers);
            let djSourceCodes = [],
                otherSourceCodes = [];
            if (keywords && keywords.length > 0) {
                djSourceCodes = this._getSourceCodesByProviders(
                    providers.filter(item => isDJNews(item))
                );
                otherSourceCodes = this._getSourceCodesByProviders(
                    providers.filter(item => !isDJNews(item))
                );
                if (this.watchedSettings.newsType === NEWS_TYPES.US_MARKETS) {
                    djSourceCodes = djSourceCodes.filter(
                        sc => utils.inArray(['DJNMPNAE', 'DJNMNDJG'], sc) > -1
                    );
                }
            }
            let complexQuery = '';
            if (djSourceCodes.length > 0) {
                if (otherSourceCodes.length > 0) {
                    complexQuery = `(sourceCode:(${otherSourceCodes.join(
                        ' '
                    )}) OR `;
                }
                complexQuery += `(sourceCode:(${djSourceCodes.join(
                    ' '
                )}) ${keywords})`;
                if (otherSourceCodes.length > 0) {
                    complexQuery += ')';
                }
            }
            return {
                newsType: this.watchedSettings.newsType,
                complexQuery,
                sourceCodes: this._getSourceCodesByProviders(providers)
            };
        },
        providersList() {
            const providers = this._getProviders();
            return providers.map(provider => {
                const d = utils.extend(true, {}, provider);
                if (d.children) {
                    d.children = d.children.map(ch => {
                        ch.visible = this._isVisibleProviders(ch.name);
                        return ch;
                    });
                } else {
                    d.visible = this._isVisibleProviders(d.name);
                }
                return d;
            });
        },
        dataPointsExpanded() {
            return (
                '' +
                (this.settingsPopoverVisible &&
                    this.settingsPopoverNav &&
                    this.settingsPopoverNav.id === 'providers')
            );
        }
    },
    watch: {
        newsTypeAndSource: {
            handler() {
                if (this.availableProviders) {
                    this.$emit(
                        'news-type-or-source-changed',
                        this.newsTypeAndSource
                    );
                }
            },
            deep: true
        },
        availableProviders: {
            handler() {
                let providersArray = [];
                if (this.availableProviders) {
                    providersArray = this.availableProviders
                        .map(item => {
                            return {
                                id: item.vendor,
                                name:
                                    this.initedLabels[item.vendor] ||
                                    item.vendor,
                                sourceCodes: item.sourceCodes,
                                visible: true
                            };
                        })
                        .sort(function(a, b) {
                            if (a.name > b.name) {
                                return 1;
                            } else {
                                return -1;
                            }
                        });
                    const isSelected = (provider, defaultSourceCodes) => {
                        const _provider = utils.extend(true, {}, provider);
                        _provider.selected = defaultSourceCodes
                            ? utils.inArray(
                                  defaultSourceCodes.split(','),
                                  code => {
                                      return (
                                          utils.inArray(
                                              provider.sourceCodes,
                                              code
                                          ) > -1
                                      );
                                  }
                              ) > -1
                            : true;
                        return _provider;
                    };
                    this.investmentNewsProviders = providersArray.map(item => {
                        const _item = isSelected(
                            item,
                            this.settings.sourceCodes
                        );
                        if (isDJNews(_item)) {
                            _item.children = this._getDJNewsTypes(
                                _item,
                                this.settings.investmentDJNewsTypes
                            );
                        }
                        return _item;
                    });
                    this.usMarketsProviders = providersArray
                        .filter(item => {
                            return (
                                utils.inArray(US_MARKETS_SOURCE_CODES, code => {
                                    return (
                                        utils.inArray(item.sourceCodes, code) >
                                        -1
                                    );
                                }) > -1
                            );
                        })
                        .map(item => {
                            const _item = isSelected(
                                item,
                                this.settings.usMarketsSourceCodes
                            );
                            _item.sourceCodes = _item.sourceCodes.filter(
                                code => {
                                    return (
                                        utils.inArray(
                                            US_MARKETS_SOURCE_CODES,
                                            code
                                        ) > -1
                                    );
                                }
                            );
                            if (isDJNews(_item)) {
                                _item.children = this._getDJNewsTypes(
                                    _item,
                                    this.settings.usMarketsDJNewsTypes
                                );
                            }
                            return _item;
                        });
                }
            },
            deep: true
        },
        security(value) {
            if (value) {
                this.watchedSettings.hasActiveNews = false;
                this.changeSettings(this._getStoreSettings());
            }
        },
        'watchedSettings.newsType': function handler(value) {
            if (value) {
                this.changeSettings(this._getStoreSettings());
                this.trackEvent({
                    name: 'news-type-change',
                    value
                });
            }
        },
        investmentNewsProviders(value) {
            this._trackProviders(value);
        },
        usMarketsProviders(providers) {
            this._trackProviders(providers);
        },
        dataModel(value) {
            if (value && value.news && value.news.length > 0) {
                if (!this.watchedSettings.hasActiveNews && !this.isMobile) {
                    this.getNewsDetail(value.news[0].resourceId, true);
                }
            }
        },
        isMobile(value) {
            if (!value) {
                this.contentDisplay = true;
                if (
                    !this.watchedSettings.hasActiveNews &&
                    this.dataModel &&
                    this.dataModel.news &&
                    this.dataModel.news.length
                ) {
                    this.getNewsDetail(this.dataModel.news[0].resourceId, true);
                }
            }
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('news');
        this.settingsPopoverTitle = this.initedLabels['settingTitle'];
    },
    methods: {
        _getDJNewsTypes(item, savedNewsTypes) {
            savedNewsTypes = savedNewsTypes ? savedNewsTypes.split(',') : [];
            return DJ_NEWS_TYPES.map(d => {
                return {
                    id: d.id,
                    name: this.initedLabels[d.id],
                    selected:
                        item.selected &&
                        (savedNewsTypes.length === 0 ||
                            utils.inArray(savedNewsTypes, d.id) > -1),
                    keywords: d.keywords,
                    visible: true
                };
            });
        },
        _getProviders() {
            return this.watchedSettings.newsType === NEWS_TYPES.INVESTMENT
                ? this.investmentNewsProviders
                : this.usMarketsProviders;
        },
        _trackProviders(providers) {
            if (providers && utils.isArray(providers)) {
                providers.forEach(provider => {
                    if (provider.selected === true) {
                        // Tracking: list of news providers set by user.
                        this.trackEvent({
                            name:
                                this.watchedSettings.newsType ===
                                NEWS_TYPES.US_MARKETS
                                    ? 'us-market-provider-select'
                                    : 'investment-provider-select',
                            value: provider.name
                        });
                    }
                });
            }
        },
        _resizeObserverCallback({ entry }) {
            this.hideSettingsPopover();
            this.watchedSettings.width = entry.contentRect.width;
        },
        toggleSettings(e) {
            this.settingsPopoverTriggeredId = utils.computeTriggerId(
                e.currentTarget
            );
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
        },
        changeNewsType(item) {
            if (
                (this.settingsPopoverNavSelected &&
                    this.settingsPopoverNavSelected.id === 'types') ||
                !this.settingsPopoverVisible
            ) {
                this.watchedSettings.newsType = item.id;
                this.watchedSettings.hasActiveNews = false;
                this.changeSettings(this._getStoreSettings());
                this.settingsPopoverVisible = false;
            }
        },
        handleCustomDataPointsClick(target) {
            this.toggleSettings(target);
            this.settingsPopoverShowDone = true;
            this.settingsPopoverTitle = this.initedLabels['newsProviders'];
            this.settingsPopoverNavSelected = {
                id: 'providers',
                title: 'News Providers'
            };
        },
        changeSettingsNav(item) {
            this.settingsPopoverNavSelected = item;
            this.settingsErrorMsg = '';
            if (item && item.id === 'providers') {
                this.settingsPopoverShowDone = true;
                this.settingsPopoverTitle = this.initedLabels['newsProviders'];
            } else if (item && item.id === 'types') {
                this.settingsPopoverShowDone = false;
                this.settingsPopoverTitle = this.initedLabels['newsTypes'];
            } else if (!item) {
                this.settingsPopoverTitle = this.initedLabels['settingTitle'];
                this.settingsPopoverShowDone = false;
            }
            this.$refs.newsUiSettings.enableTrapFocus();
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.settingsPopoverNavSelected = null;
            this.settingsPopoverShowDone = false;
            this.settingsErrorMsg = '';
            this.$emit('settings-popover-hide');
        },
        hideDetail() {
            this.backBtnClicked = true;
            this.$refs.aside.focusActiveNews();
        },
        filterProviders(keyword) {
            this.searchKey = keyword;
        },
        _isVisibleProviders(name) {
            return this.searchKey
                ? name.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1
                : true;
        },
        transformNewsData(data) {
            if (data.format) {
                return data;
            }
            const dateTime = this.formatter.date(
                new Date(data.publicationTime)
            );
            //remove the ie browser unicode
            const formatTime = dateTime.replace(/\u200e/g, '');
            data.publicationTime = formatTime;
            data.format = true;
            return data;
        },
        asideScroll() {
            this.$emit('aside-scrolled');
        },
        getNewsDetail(id, innerCall) {
            if (this.dataDetail.resourceId !== id || this.isMobile) {
                this.$emit('details-displayed', id);
            }
            if (!innerCall) {
                this.backBtnClicked = false;
            }
            this.watchedSettings.hasActiveNews = true;
            if (this.$refs.content) {
                this.$refs.content.focusOnContent();
            }
        },
        changeSourceCode() {
            if (this.checkedProvidersList) {
                const providerList = this._getProviders().map(provider => {
                    if (provider.children) {
                        let isSelected = false;
                        provider.children.forEach(ch => {
                            if (this.checkedProvidersList[provider.id]) {
                                ch.selected = this.checkedProvidersList[
                                    provider.id
                                ][ch.id];
                                if (ch.selected) {
                                    isSelected = true;
                                }
                            }
                        });
                        provider.selected = isSelected;
                    } else {
                        provider.selected = this.checkedProvidersList[
                            provider.id
                        ];
                    }
                    return provider;
                });
                const sourceCodes = this._getSourceCodesByProviders(
                    providerList
                ).join(',');
                const newsTypes = this._getDJNewsTypesByProviders(providerList)
                    .newsTypes;
                if (this.watchedSettings.newsType === NEWS_TYPES.US_MARKETS) {
                    this.usMarketsProviders = providerList;
                    this.watchedSettings.usMarketsSourceCodes = sourceCodes;
                    this.watchedSettings.usMarketsDJNewsTypes = newsTypes;
                } else {
                    this.investmentNewsProviders = providerList;
                    this.watchedSettings.sourceCodes = sourceCodes;
                    this.watchedSettings.investmentDJNewsTypes = newsTypes;
                }
                this.changeSettings(this._getStoreSettings());
            }
            this.settingsPopoverVisible = false;
            this.watchedSettings.hasActiveNews = false;
        },
        changeCheckedProviders(data) {
            this.checkedProvidersList = data;
            const hasChecked = obj => {
                for (const key in obj) {
                    if (typeof this.checkedProvidersList[key] === 'object') {
                        const checked = hasChecked(
                            this.checkedProvidersList[key]
                        );
                        if (checked) {
                            return true;
                        }
                    } else {
                        if (obj[key]) {
                            return true;
                        }
                    }
                }
                return false;
            };
            if (hasChecked(this.checkedProvidersList)) {
                this.settingsErrorMsg = '';
            } else {
                this.settingsErrorMsg = this.initedLabels[
                    'noProviderSelectedMsg'
                ];
            }
        },
        changeATagRedirect(content) {
            return content.replace(/<a\shref=".*?"(?!\starget=".*?")>/g, $1 => {
                if ($1.indexOf('target') < 0) {
                    $1 = $1.replace(/<a\shref/g, '<a target="_blank" href');
                }
                return $1;
            });
        },
        _getDJNewsTypesByProviders(providers) {
            const isInvestmentNews =
                this.watchedSettings.newsType === NEWS_TYPES.INVESTMENT;
            const DJProvider = utils.find(providers, p => isDJNews(p));
            let keywords = '',
                newsTypes;
            if (DJProvider && DJProvider.selected) {
                const selectedTypes = [];
                const unSelectedTypes = [];
                const regularTypes = [];
                let otherType;

                DJProvider.children.forEach(ch => {
                    if (ch.selected) {
                        if (ch.id !== 'other') {
                            regularTypes.push(ch);
                        } else {
                            otherType = ch;
                        }
                        selectedTypes.push(ch);
                    } else {
                        unSelectedTypes.push(ch);
                    }
                });

                // us market, set keywords as US_MARKETS_KEYWORDS
                if (!isInvestmentNews) {
                    keywords = `AND keywords:("${US_MARKETS_KEYWORDS}")`;
                }

                if (unSelectedTypes.length !== 0) {
                    // if other is selected with any regular at same time, use exclude
                    if (keywords) {
                        keywords += ' ';
                    }
                    if (otherType) {
                        keywords += `NOT ${this._generateKeywords(
                            unSelectedTypes
                        ).join(' NOT ')}`;
                    } else {
                        // all selected are regular, use include
                        keywords += `AND (${this._generateKeywords(
                            selectedTypes
                        ).join(' OR ')})`;
                    }
                }
                newsTypes = selectedTypes.map(item => item.id).join(',');
            }
            return { keywords, newsTypes };
        },
        _generateKeywords(selectedTypes) {
            const keywords = [];
            selectedTypes.forEach(item => {
                item.keywords.split(',').forEach(k => {
                    keywords.push(`keywords:${k}`);
                });
            });
            return keywords;
        },
        _getSourceCodesByProviders(providers) {
            return providers
                .filter(p => p.selected)
                .reduce(
                    (accumulator, current) => {
                        return {
                            sourceCodes: [
                                ...accumulator.sourceCodes,
                                ...(current.sourceCodes || [])
                            ]
                        };
                    },
                    { sourceCodes: [] }
                ).sourceCodes;
        },
        _getStoreSettings() {
            const symbol = utils.isEmptyObject(this.security)
                ? this.watchedSettings.symbol
                : this.getSavedSymbol(this.security);
            if (
                !symbol &&
                this.watchedSettings.newsType === NEWS_TYPES.INVESTMENT
            ) {
                return null;
            }
            const settings = {
                symbol,
                newsType: this.watchedSettings.newsType
            };
            if (this.watchedSettings.sourceCodes) {
                settings.sourceCodes = this.watchedSettings.sourceCodes;
            }
            if (this.watchedSettings.usMarketsSourceCodes) {
                settings.usMarketsSourceCodes = this.watchedSettings.usMarketsSourceCodes;
            }
            if (this.watchedSettings.usMarketsDJNewsTypes) {
                settings.usMarketsDJNewsTypes = this.watchedSettings.usMarketsDJNewsTypes;
            }
            if (this.watchedSettings.investmentDJNewsTypes) {
                settings.investmentDJNewsTypes = this.watchedSettings.investmentDJNewsTypes;
            }

            return settings;
        }
    },
    components: {
        newsAside,
        newsContent
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-news';
.#{$namespace} {
    p,
    ul,
    li {
        margin: 0;
        padding: 0;
    }
    li {
        list-style-type: none;
    }
    * {
        box-sizing: border-box;
    }
    pre {
        font-family: $mds-typography-font-family-sans-serif;
    }
    font-family: $mds-typography-font-family-sans-serif;
    &-body {
        height: 100%;
        padding-top: 8px;
    }
    &-main {
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: relative;
    }
    &-navigation {
        height: 42px;
        padding: $mds-space-1-x;
        display: flex;
        .markets-ui-menus {
            margin-right: $mds-space-2-x;
            height: 26px;
        }
    }
    &-web-layout &-sub-component {
        height: calc(100% - 42px);
    }
    &-settings {
        &.mds-popover___markets.mds-popover--width-200px___markets {
            width: 215px;
        }
    }
    .markets-ui-alert__message-info {
        font-size: $mds-typography-font-size-m;
    }
    &.markets-ui-container__dark-gray {
        background: $mds-background-color-dark-gray;
        color: $mds-text-color-primary-on-dark;
        .#{$namespace} {
            &-detail--spread {
                background: $mds-background-color-dark-gray;
            }
            &-active {
                background: $mds-color-neutral-90;
                color: $mds-color-black;
            }
            &-detail--headerIcon {
                background: $mds-background-color-dark-gray;
                svg {
                    fill: $mds-color-neutral-95;
                }
            }
        }
    }
}
</style>
