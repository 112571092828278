var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      ref: "newContent",
      class: _vm.classes,
      attrs: {
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      _c(
        "div",
        {
          class: _vm.namespace("body"),
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _vm.showWebLayout && !_vm.errorMessage
            ? _c(
                "div",
                { class: _vm.namespace("navigation") },
                [
                  _vm.hasMenu
                    ? _c("markets-ui-menus", {
                        attrs: {
                          dataModel: _vm.menuNewsTypes,
                          skin: _vm.watchedSettings.skin
                        },
                        on: { change: _vm.changeNewsType }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.watchedSettings.newsType === "investment" ||
                  _vm.watchedSettings.newsType === "usMarkets"
                    ? _c("markets-ui-button", {
                        attrs: {
                          "icon-right": "caret-down",
                          text: _vm.customButtonName,
                          skin: _vm.watchedSettings.skin,
                          "aria-expanded": _vm.dataPointsExpanded
                        },
                        on: { click: _vm.handleCustomDataPointsClick }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.errorMessage
            ? _c(
                "section",
                {
                  class: [_vm.namespace("main"), _vm.namespace("sub-component")]
                },
                [
                  _c("newsAside", {
                    ref: "aside",
                    class: _vm.asideClass,
                    attrs: {
                      dataObject: _vm.dataObject,
                      asideLoading: _vm.listLoading,
                      isMobile: _vm.isMobile,
                      skin: _vm.watchedSettings.skin,
                      activeResourceId: _vm.dataDetail.resourceId,
                      newsType: _vm.watchedSettings.newsType
                    },
                    on: {
                      "item-clicked": _vm.getNewsDetail,
                      scroll: _vm.asideScroll
                    }
                  }),
                  _vm._v(" "),
                  !_vm.errorMessage
                    ? _c("newsContent", {
                        ref: "content",
                        attrs: {
                          newsDetail: _vm.dataDetail,
                          skin: _vm.watchedSettings.skin,
                          visible: _vm.newsContentVisible,
                          isMobile: _vm.isMobile,
                          labels: _vm.initedLabels
                        },
                        on: { hide: _vm.hideDetail }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          ref: "newsUiSettings",
          class: _vm.namespace("settings"),
          attrs: {
            slot: "settings",
            "done-disable": !!_vm.settingsErrorMsg,
            "show-done": _vm.settingsPopoverShowDone,
            title: _vm.settingsPopoverTitle,
            skin: _vm.watchedSettings.skin,
            showBack: !!_vm.settingsPopoverNavSelected && !_vm.showWebLayout,
            showSearchField: _vm.showProviderSetting,
            "triggered-by": _vm.settingsPopoverTriggeredId,
            visible: _vm.settingsPopoverVisible,
            "error-message": _vm.settingsErrorMsg
          },
          on: {
            hide: _vm.hideSettingsPopover,
            back: _vm.changeSettingsNav,
            done: _vm.changeSourceCode,
            "search-key-changed": _vm.filterProviders
          },
          slot: "settings"
        },
        [
          !_vm.settingsPopoverNavSelected
            ? _c("markets-ui-settings-navigation", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNav
                },
                on: { change: _vm.changeSettingsNav },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.showNewsTypeSetting
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  size: "small",
                  skin: _vm.watchedSettings.skin,
                  "data-model": _vm.newsTypes,
                  variation: "selection"
                },
                on: { change: _vm.changeNewsType },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showProviderSetting
            ? _c(
                "markets-ui-form",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("markets-ui-checkbox-group", {
                    attrs: {
                      skin: _vm.watchedSettings.skin,
                      "data-model": _vm.providersList
                    },
                    on: { change: _vm.changeCheckedProviders }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }