<template>
    <aside ref="newsAside">
        <div :class="namespace('ul-container')" ref="scrollEl">
            <ul
                :class="namespace('ul')"
                @click.stop="getNewsDetail"
                @keydown.enter="getNewsDetail"
                ref="newsContent"
            >
                <li
                    v-for="(item, index) in dataObject.news"
                    tabindex="0"
                    :key="index"
                    :resourceId="item.resourceId"
                    :aria-describedby="ariaDescribedbyId"
                    @mouseenter="showTip(item, $event)"
                    @mouseleave="hideTip"
                    @focus="showTip(item, $event)"
                    @blur="hideTip"
                    :class="
                        item.resourceId === activeId
                            ? [namespace('li'), namespace('li-active')]
                            : namespace('li')
                    "
                >
                    <img
                        :class="namespace('logo')"
                        :alt="item.logoAlt"
                        :src="'data:image/png;base64,' + item.logoBase64Image"
                    />
                    <p :class="namespace('aside_title')">
                        {{ item.title }}
                    </p>
                    <p :class="namespace('aside_time')">
                        {{ item.publicationTime }}
                    </p>
                </li>
            </ul>
        </div>
        <!--why use v-if instead of visible prop here: the mds popover will change the focus when visible prop change-->
        <markets-ui-tooltip
            ref="asidePopover"
            :class="namespace('aside__popover')"
            :visible="tooltipVisible"
            :triggered-by="triggeredId"
            :skin="skin"
        >
            <p :class="namespace('aside__popover-content')">{{ title }}</p>
        </markets-ui-tooltip>
        <markets-ui-loading
            :skin="skin"
            :visible="asideLoading"
        ></markets-ui-loading>
    </aside>
</template>
<script>
import BaseMixin from './mixins';
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
export default {
    mixins: [BaseMixin],
    props: {
        dataObject: {
            type: Object,
            required: true
        },
        asideLoading: {
            type: Boolean,
            required: false
        },
        activeResourceId: {
            type: String,
            required: false
        },
        newsType: {
            type: String
        },
        ariaDescribedbyId: {
            type: String
        }
    },
    data() {
        return {
            tooltipVisible: false,
            triggeredId: undefined,
            title: '',
            activeId: ''
        };
    },
    watch: {
        activeResourceId(value) {
            if (value) {
                this.activeId = value;
            }
        },
        dataObject() {
            this.checkAndTriggerScroll();
        }
    },
    mounted() {
        this.$refs.scrollEl.addEventListener(
            'scroll',
            utils.debounce(e => this.scrollEvent(e), 200)
        );
        utils.resizeObserver.observe(this.$el, this.checkAndTriggerScroll);
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(this.$el, this.checkAndTriggerScroll);
    },
    methods: {
        showTip(item, e) {
            const pEl = e.target.querySelector(
                `.${this.namespace('aside_title')}`
            );
            // check if there is an ellipsis
            if (pEl.scrollWidth === pEl.offsetWidth) {
                this.tooltipVisible = false;
            } else {
                this.triggeredId = utils.computeTriggerId(e.target);
                this.tooltipVisible = true;
                this.title = item.title;
            }
        },
        hideTip() {
            this.tooltipVisible = false;
        },
        scrollEvent(e) {
            this.hideTip();
            const _el = e.target;
            if (
                this.dataObject.news.length < this.dataObject.total &&
                _el.scrollTop + _el.clientHeight >= _el.scrollHeight - 10
            ) {
                this.$emit('scroll');
            }
        },
        getNewsDetail(e) {
            const liEl = utils.closest(e.target, this.namespace('li'), true);
            if (liEl) {
                const resourceId = liEl.getAttribute('resourceid');
                this.activeId = resourceId;
                if (resourceId) {
                    this.$emit('item-clicked', resourceId);
                }
            }
        },
        checkAndTriggerScroll() {
            this.$nextTick(() => {
                const containerHeight = this.$refs.newsAside.offsetHeight;
                const contentHeight = this.$refs.newsContent.offsetHeight;
                if (
                    contentHeight < containerHeight &&
                    this.dataObject.news.length < this.dataObject.total
                ) {
                    this.$emit('scroll');
                }
            });
        },
        focusActiveNews() {
            const activeEl = this.$el.querySelector(
                `.${this.namespace('li-active')}`
            );
            if (activeEl) {
                this.$nextTick(() => {
                    activeEl.focus();
                });
            }
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';

$namespace: 'mwc-markets-news';
$font-size: $mds-typography-font-size-s;
.#{$namespace}-aside {
    width: 350px;
    height: 100%;

    &__popover {
        font-size: $font-size;
    }
    &__popover-content {
        font-size: $font-size;
        margin: 0;
    }
    .markets-ui-loading {
        top: calc(100% - 90px); /*loading size is 80px*/
    }
    .#{$namespace}-ul-container {
        height: 100%;
        overflow: auto;
    }
    .#{$namespace}-ul {
        padding: 5px;
        font-size: $font-size;
        p {
            font-size: $font-size;
            line-height: 18px;
        }
    }

    .#{$namespace}-li,
    .#{$namespace}-li-active {
        display: flex;
        border-bottom: 1px solid rgba(128, 128, 128, 0.19);
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 3px 0;
        &:focus {
            box-shadow: $mds-box-shadow-focus;
            outline: none;
        }
    }
    .#{$namespace}-li-active {
        background: $mds-color-neutral-67;
    }
    .#{$namespace}-logo {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }
    &_title {
        width: 245px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 300;
    }
    &_time {
        font-weight: $mds-typography-font-weight-regular;
        color: $mds-color-neutral-37;
        white-space: nowrap;
    }
    &--hide {
        display: none;
    }
    &--mobile {
        position: absolute;
        width: 100%;
        .#{$namespace}-ul {
            padding: 10px;
            .#{$namespace} {
                &-aside_title {
                    width: 100%;
                    padding-left: 10px;
                }
                &-aside_time {
                    text-align: right;
                }
            }
        }
    }
}
</style>
